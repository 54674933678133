<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="800"
      :mask-closable="false"
      title="新订单通知"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <template slot="footer">
        <div style="text-align: center">
          <a-space>
            <a-button key="submit" type="primary" @click="handleSubmit">
              标记为全部已读
            </a-button>
            <a-button key="back" @click="handleCancel">
              关闭
            </a-button>
          </a-space>
        </div>
      </template>
      <a-table
        size="middle"
        :columns="tableColumns"
        :data-source="tableData"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350 }"
        row-key="id"
      >
        <span slot="actions" slot-scope="text, record">
          <a-space>
            <a @click="showInfo(record)">查看详情</a>
          </a-space>
        </span>
      </a-table>
    </a-modal>

    <show-system-order-info
      v-if="isShowInfoModal && isShowMenu('menu:system_orders')"
      :visible.sync="isShowInfoModal"
      :record-id="recordId"
      :id="orderId"
      :order-type="orderType"
    />

    <show-tenant-order-info
      v-if="isShowInfoModal && isShowMenu('menu:tenant_orders')"
      :visible.sync="isShowInfoModal"
      :id="orderId"
      :order-type="orderType"
    />
  </div>
</template>

<script>
import { findNewOrderNotices, readAllNewOrderNotices, readNotice } from '@/api/notice'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'NoticeIndex',
  components: {
    ShowSystemOrderInfo: () => import('@/views/orders/system/Show'),
    ShowTenantOrderInfo: () => import('@/views/orders/tenant/Show')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      submitting: false,
      isShowInfoModal: false,
      recordId: 0, // 订单关联表id
      orderId: 0, // 订单id
      orderType: '', // 订单类型
      tableColumns: [
        {
          title: '订单编号',
          dataIndex: 'order_no',
          fixed: 'left',
          width: 220
        },
        {
          title: '订单类型',
          dataIndex: 'order_type'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          width: 120,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ],
      tableData: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchData() {
      this.loading = true
      findNewOrderNotices().then((res) => {
        this.tableData = res.data
        this.loading = false
      })
    },

    showInfo(record) {
      this.isShowInfoModal = true
      this.recordId = record.order_record_id
      this.orderId = record.order_id
      this.orderType = record.order_type_slug
      // 标记为已读
      readNotice(record.id).then((res) => {
        this.$pullNotifications()
      })
    },

    handleCancel() {
      this.isShow = false
    },

    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      readAllNewOrderNotices().then((res) => {
        if (res.code === 0) {
          this.$pullNotifications()
          // 关闭模态框
          this.isShow = false
        }
        this.submitting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-tips {
  padding-top: 20px;
}
</style>
